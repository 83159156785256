.ore-toolbar {
  //   width: 100%;
}

@media (min-width: 1200px) {
  .ore-toolbar {
    min-width: 600px;
  }
}

.ore-toolbar__wrapper {
  position: sticky;
  bottom: 0;
  //   background-color: red;
  padding: 2em 0em;

  // new
  background-color: var(--color-neutral-200);
  border-radius: var(--radius-small);
  flex-direction: column;
  display: flex;
  gap: var(--spacing-small);
  justify-content: space-between;
  padding: var(--spacing-small);
}

.ore-toolbar__wrapper {
  align-items: center;
  flex-direction: row;
  padding: var(--spacing-medium) var(--spacing-large);
}

.ore-toolbar__actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
}

.ore-toolbar__actions {
  align-items: flex-end;
  flex-direction: row;
}

.ore-toolbar__actions > * {
  flex-basis: fit-content;
}

.ore-toolbar--sticky {
  position: sticky;
  bottom: 0;
  // background-color: red;
  padding: 2em 0em;
  // bottom: var(--spacing-small);
  // left: 0;
  // margin: 0 auto;
  // position: relative;
  // right: 0;
  // width: calc(100% - 24px);
  z-index: 30;
}

.ore-toolbar--sticky .ore-toolbar__wrapper {
  box-shadow: var(--shadow-large);
}
