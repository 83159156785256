.alert {
  position: fixed;
  bottom: 25px;
  left: 25px;
  border-radius: var(--radius-medium);
  box-shadow: none;
  background-color: #edf7ed;
  padding: 8px 16px;
  color: #1e4620;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  max-width: 350px;
  width: 100%;
  height: 55px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px,
    rgb(0 0 0 / 12%) 0px 1px 18px 0px;

  p {
    display: flex;
    gap: 10px;
    font-weight: 600;
    font-size: 12px;
    color: inherit;
    margin: auto auto auto 15px;
  }

  button {
    color: inherit;
  }
}

.alert--error {
  color: var(--color-error-300);
  background-color: #fdeded;
}

.alert--success {
  color: #1e4620;
  background-color: #edf7ed;
}

#alert-show {
  visibility: visible;
  animation: fadeIn 0.4s, fadeOut 0.4s 6.6s;
}

#alert-hide {
  visibility: hidden;
}

@keyframes fadeIn {
  from {
    left: -350px;
    bottom: 25px;
  }
  to {
    left: 25px;
    bottom: 25px;
  }
}

@keyframes fadeOut {
  from {
    bottom: 25px;
    left: 25px;
  }
  to {
    bottom: 25px;
    left: -350px;
  }
}
