.close-modal-wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

.modal-list-description li {
  margin-left: 30px;
}
