.ore-table {
  min-width: auto !important;

  thead tr th {
    svg path {
      fill: var(--color-neutral-600);
    }
  }
}

.table-columns-even {
  table-layout: fixed;

  &.has-selector {
    th:first-child,
    td:first-child {
      width: 45px;
    }
  }
}

.table--no-input-icons {
  .icon-placeholder {
    display: none;
  }
}

[data-disabled-row='true'] {
  background-color: var(--color-neutral-100);

  &:hover {
    background-color: var(--color-neutral-100);
  }

  p {
    color: var(--color-neutral-400);
  }
}
