.flex {
  display: flex;
}

.flex--row {
  flex-direction: row;
  gap: 1rem;
}

.flex--column {
  flex-direction: column;
}

.gap-1 {
  gap: 1rem;
}

.gap-05 {
  gap: 0.5rem;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-105 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-207 {
  margin-top: 2.7rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-025 {
  margin-bottom: 0.25rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-105 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-205 {
  margin-bottom: 2.5rem;
}

.mb-207 {
  margin-bottom: 2.7rem;
}
.mb-3 {
  margin-bottom: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-105 {
  margin-right: 1.5rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-105 {
  margin-left: 1.5rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-105 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-105 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-105 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-105 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-105 {
  padding-bottom: 1.5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.p-1 {
  padding: 1rem;
}

.p-05 {
  padding: 0.5rem;
}

.p-105 {
  padding: 1.5rem;
}

.p-2 {
  padding: 2rem;
}

.p-205 {
  padding: 2.5rem;
}

.p-3 {
  padding: 3rem;
}

.p-305 {
  padding: 3.5rem;
}

.p-05 {
  padding: 0.5rem;
}

.pt-105 {
  padding-top: 1.5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-05 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-05 {
  padding-left: 0.5rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-left: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.float-right {
  float: right;
}

.ml-auto {
  margin-left: auto;
}

.inline-flex {
  display: inline-flex;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.one {
  grid-column: 1/3;
  grid-row: 1;
}

.two {
  grid-column: 3;
  grid-row: 1;
  display: table;

  span {
    display: table-cell;
    vertical-align: middle;
  }
}

.three {
  grid-column: 4;
  grid-row: 1;
  display: table;

  span {
    display: table-cell;
    vertical-align: middle;
  }
}

div.pac-container {
  z-index: 99999999999 !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.pointer:hover {
  cursor: pointer;
}

.text-nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}

.width-80 {
  width: 80%;
}

.width-85 {
  width: 85%;
}

.width-90 {
  width: 90%;
}

.width-95 {
  width: 95%;
}

.width-100 {
  width: 100%;
}

.bg-white {
  background-color: var(--color-neutral-white);
}

.bg-grey {
  background-color: var(--color-neutral-100);
}

.whitespace-nowrap {
  white-space: nowrap;
}

.tbcontainer {
  position: relative;
}

.tbcontainer2 {
  position: absolute;
  bottom: 0;
}

.ore-toolbar ore-toolbar--sticky {
  position: relative;
}

.bold {
  font-weight: bold;
}

.info-message {
  background-color: #f1f5f9;
  border: 1px solid #e7ecf2;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.hover-pointer:hover {
  cursor: pointer;
}

.video-responsive {
  overflow: hidden;
  position: relative;
  padding: 2rem 1rem 1rem 1rem;
  height: 480px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.ore-list li {
  margin-top: 0px;
  margin-left: 1rem;
}

.svg-danger {
  svg path {
    fill: #f72717;
  }

  &:hover {
    svg path {
      fill: #f72717 !important;
    }
  }
}

.ol-ore-text {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5;
  font-family: Ubuntu, sans-serif;
  color: var(--color-neutral-600);
  padding-left: 1.5rem;
}

.list-items-space-narrow {
  li + li {
    margin-top: 0 !important;
  }
  ul,
  ol {
    margin: 0.5rem 0 !important;
  }
}

.ore-distributor {
  height: 100%;
}

.ore-distributor-list {
  z-index: 9999;
}
