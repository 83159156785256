.navbar {
  border-radius: 16px;
  margin-bottom: 0;
  margin-top: 60px;
  background-color: #e9e9e9;
  min-width: 300px;
  height: fit-content;
  padding: 15px 20px;

  h4 {
    margin-bottom: 5px;
  }

  ul {
    display: flex;
    gap: 16px;
  }
}

.nav__link {
  margin-top: 5px;
  color: #162056;

  font-weight: 500;
}

.nav__link--active {
  color: #2897ff;
  font-weight: 600;
}

.navbar-details {
  margin-top: 48px;
  padding: 14px 20px;
  background: #e9e9e95d;

  p {
    font-size: 13px;
    line-height: 150%;

    span {
      font-weight: 600;
    }
  }
}

.multicif-navbar:hover {
  cursor: pointer;
  background-color: var(--color-neutral-100);
}

.multicif-navbar {
  padding: 10px;
}

.wide-ore-navbar-user .ore-navbar-user-list {
  min-width: 300px;
}

.hover-cursor:hover {
  cursor: pointer;
}

.navbar-user ul {
  max-height: 700px;
  overflow: auto;
  margin-top: 19px;
}
