.card-container {
  padding: 0 1.5rem 1rem 1.5rem;
}

.card-title {
  padding: 1.5rem 1rem 0 1rem;
}

.card-row {
  border-top: 2px solid var(--color-neutral-200);
  padding: 0.5rem 0 1rem 0;
}
