.grid {
  display: grid;
  @for $i from 2 through 12 {
    &.grid-col-#{$i} {
      grid-template-columns: repeat($i, 1fr);
    }
  }
}

.grid-item {
  // width: 100%; // FLEX Mode
}
