.hero {
  padding: 32px 0 0 0;

  &::before {
    content: ' ';
    height: 404px;
    width: 70%;
    background-color: var(--color-cobalt-100);
    border-top-right-radius: 384px;
    border-bottom-right-radius: 384px;
    position: absolute;
    top: calc(var(--header-height) + 3rem);
    left: 0;
    z-index: -1;
  }
}

.hero.hero--reduced {
  padding: 32px 0 0 0;
}

.hero.hero--homeflex {
  padding: 22px 0 0;
  min-height: 220px;
  &::before {
    height: 220px !important;
  }
}

.hero-pt-reduced {
  padding-top: 25px;
}

@media (min-width: 1200px) {
  .hero.hero--reduced {
    padding: 32px 0 0 0;
  }

  .hero-pt-reduced {
    padding-top: 0;
  }
}

.hero__bullet {
  display: inline-block;
  width: 51px;
  height: 27px;
  background-color: var(--color-primary-500);
  border-radius: 28px;
  margin-right: 0.5rem;
  margin-bottom: -2px;
}

.hero.hero--home {
  &::before {
    height: 256px;
  }
  .hero__content {
    h1 {
      max-width: 612px;
    }
    p {
      max-width: 612px;
    }
    // .hero__bullet {
    //   margin-bottom: 0.25rem;
    // }
  }
}
