.spinner--container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #80808096;
  z-index: 9;
}

.loader--container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: var(--color-neutral-900);
  opacity: 0.35;
  z-index: 99999;
}

.spinner-app {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 75px;
  width: 75px;
  margin: -25px 0 0 -25px;
  border: 5px var(--color-neutral-500) solid;
  border-top: 5px solid var(--color-cobalt-400);
  border-radius: 50%;
  -webkit-animation: spin2 1.5s infinite linear;
  animation: spin2 1.5s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
