* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--color-neutral-50);
  font-size: 16px;
}

button {
  border: none;
}
