.account-container--center {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  margin: 0 20%;

  &.account-deleted-container {
    justify-content: center;
    height: 100vh;
  }
}

.terms-conditions-link:hover {
  cursor: pointer;
}

.terms-conditions-link {
  text-decoration: underline;
}
