.form-organism {
  margin: auto;
  padding: 4rem 3rem;
  width: 100%;
  background-color: var(--color-neutral-white);
  overflow: visible;
  box-shadow: var(--shadow-large);
  border-radius: var(--radius-medium);
}

.table-wrapper {
  margin: auto;
  padding: 0.7rem 0rem 0.7rem 0rem;
  width: 100%;
  background-color: var(--color-neutral-white);
  overflow: visible;
  box-shadow: var(--shadow-large);
  border-radius: var(--radius-medium);
}
.column-wrapper-buttons {
  margin-top: 1.3rem;
}

.pagination-table {
  padding: 0.7rem 0.7rem 0rem;
}

.form-molecule::before,
.form-molecule::after {
  display: table;
  content: '';
}

.form-molecule::after {
  clear: both;
}

.form-molecule {
  position: relative;
}

.form-row {
  display: flex; // new
  flex-wrap: wrap;
  width: 100%;
  // float: left;

  &.form-row-nopadding {
    .form-atom {
      &:nth-child(odd) {
        padding-left: 0;
      }
      &:nth-child(even) {
        padding-right: 0;
      }
    }
  }
}

.form-atom {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // float: left;
  display: block;
}

.form-atom--one {
  width: 100%;
}

.form-atom--two {
  width: 100%;
}

.form-atom--three {
  width: 100%;
}

.form-atom--half {
  width: 100%;
}

@media (min-width: 768px) {
  .form-atom--one {
    width: 30%;
  }

  .form-atom--two {
    width: 70%;
  }

  .form-atom--three {
    width: 33.3333333333%;
  }

  .form-atom--half {
    width: 50%;
  }
}

.ds-selected-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin: 0.4rem;
  background-color: #f1f5f9;
  border-radius: 1rem;
}

.ds-selected--flex-column {
  flex-direction: column;
  gap: 0.5rem;
}

.ds-selected--gap-1-5 {
  gap: 1.5rem;
}

.svgForm svg path {
  fill: #711cff;
}
.svgExcel svg path {
  fill: #00a184;
}

// .svgSettings svg path {
//   fill: none;
//   stroke: var(--color-neutral-600);
// }

// .svgSettings button:hover svg path {
//   fill: none !important;
//   stroke: var(--color-neutral-800) !important;
// }

.svg-fill--blue {
  display: block;
  background-color: #086ac7;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: #ffffff;
    }
  }
}
