.home-tables-grid {
  &::before {
    content: ' ';
    height: 384px;
    width: 75%;
    background-color: var(--color-cobalt-100);
    border-top-left-radius: 384px;
    border-bottom-left-radius: 384px;
    position: absolute;
    bottom: 165px;
    right: 0;
    z-index: -1;
  }
}

.incomplete-order-home--wrapper {
  height: 100%;
  .ore-table-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ore-table {
    flex: 1;
  }
}

/* Kindergarten Home */
.kindergarten-home__container {
  display: flex;
  justify-content: center;
  align-content: center;
  .kindergarten-home__wrapper {
    position: relative;
    padding: 0 1rem;
  }
}

.kindergarten-home__hero-image {
  max-width: 260px;
}

.kindergarten-home__hero-circle {
  position: absolute;
  bottom: 0;
  right: -18%;
  z-index: -1;
}
