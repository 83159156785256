.sidebar {
  background-color: white;
  z-index: 999;

  .ore-nav-bar-vertical--collapse:not(.ore-nav-bar-vertical--hover) {
    .ore-nav-bar-vertical__copyright {
      display: none;
    }
  }

  .ore-nav-bar-vertical__copyright {
    margin-top: 1rem;
    white-space: nowrap;
  }

  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-neutral-900);
    opacity: 0.5;
  }
}
