.page-wrapper {
  min-height: 80vh;
  padding-top: calc(var(--header-height) + 3rem);
  padding-bottom: 4rem;
  max-width: var(--page-max-width);
  overflow: visible;
  margin: auto;
}

.content-layout {
  width: 100%;
  padding-left: var(--sidebar-width);

  @include desktop-up {
    padding-left: var(--sidebar-width);
  }

  &.content-layout--collapsed {
    @include desktop-up {
      padding-left: var(--sidebar-expanded-width);
    }
  }
}

.main-page {
  padding: 0 2rem;
  width: 100%;
  min-height: 80vh;
  position: relative;
}
