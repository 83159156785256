.divider {
  background-color: var(--color-neutral-200);
  border: 1px solid var(--color-neutral-200);
  margin: 1.5rem 0 1.5rem 0;
  height: 2px;
  left: 0px;
  top: 64px;
  border-radius: 100px;
  flex-basis: 100%;
}
