// Responsive Breakpoints
$phone-down: 499px;
$tablet-down: 749px;
$tablet-up: 750px;
$desktop-down: 1023px;
$desktop-up: 1024px;
$desktop-md-down: 1379px;
$desktop-md-up: 1380px;
$desktop-lg-down: 1599px;
$desktop-lg-up: 1600px;
$extended-down: 1999px;
$extended-up: 2000px;

// Responsive Mixins
@mixin phone-only {
  @media (max-width: $phone-down) {
    @content;
  }
}
@mixin tablet-down {
  @media (max-width: $tablet-down) {
    @content;
  }
}
@mixin tablet-up {
  @media (min-width: $tablet-up) {
    @content;
  }
}
@mixin desktop-down {
  @media (max-width: $desktop-down) {
    @content;
  }
}
@mixin desktop-up {
  @media (min-width: $desktop-up) {
    @content;
  }
}
@mixin desktop-md-down {
  @media (max-width: $desktop-md-down) {
    @content;
  }
}
@mixin desktop-md-up {
  @media (min-width: $desktop-md-up) {
    @content;
  }
}
@mixin desktop-lg-down {
  @media (max-width: $desktop-lg-down) {
    @content;
  }
}
@mixin desktop-lg-up {
  @media (min-width: $desktop-lg-up) {
    @content;
  }
}
@mixin extended-down {
  @media (max-width: $extended-down) {
    @content;
  }
}
@mixin extended-up {
  @media (min-width: $extended-up) {
    @content;
  }
}
