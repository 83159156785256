.distributor-left {
  .ore-distributor-list {
    right: calc(100% + 1rem);
    bottom: 0;
  }
  .ore-distributor-list-item {
    white-space: nowrap;
  }

  .ore-distributor--open .ore-distributor__arrow svg {
    transform: rotate(90deg);
  }
}
