.footer-products {
  padding-top: 20px;
  background-color: #162056;
  height: 167px;
  padding: 20px 30px 0px;
}

.footer-products--container {
  max-width: var(--page-max-width);
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
}

.footer-products__title {
  color: var(--color-neutral-400);
  font-size: 18px;
  margin-bottom: 21px;
}

.footer-products-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
}

.footer-products-list-logos {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  li {
    margin-right: 15px;
  }
}

.footer-copyright {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--color-neutral-white);

  p {
    color: var(--color-neutral-white);
    white-space: nowrap;
    font-size: 10px;
  }

  &-legal {
    display: flex;
    flex-direction: row;
    gap: 2.5px;
    color: var(--color-neutral-white);

    li > a {
      color: var(--color-neutral-white);
      white-space: nowrap;
      font-size: 10px;
    }
  }
}
