.soo-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.soo-login {
  width: 300px;
  height: 100px;
  h2 {
    text-align: center;
  }
}
