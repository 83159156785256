.template-layout {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  flex-wrap: wrap;
}
.column-wrapper {
  position: relative;
  align-items: flex-start;
  gap: 3rem;
  flex-wrap: wrap;
}

.width-max {
  flex: 100%;
}
