.ore-tooltipCustomWarning {
  padding: var(--spacing-xsmall) var(--spacing-small);
  background-color: var(--color-neutral-700);
  color: var(--color-neutral-white);
  border-radius: var(--radius-small);
  box-shadow: var(--shadow-large);
  line-height: 1;
  max-width: 14rem;
  z-index: 99999;

  &__arrow {
    position: absolute;
    background: var(--color-neutral-700);
    border-radius: 2px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    z-index: -1;
  }
}
